
export const ADMIN_PANEL_ROUTES = {
    ROUTES:[
        '/admin/panel/catalog-settings',
        '/admin/panel/products-settings',
        '/admin/panel/registration-settings',
        '/admin/panel/blog-settings',
    ],
    SIGN_IN: '/admin'
};
