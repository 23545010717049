export const PRODUCT_DICTIONARY = {
    DESCRIPTION_LABEL: 'Описание:',
    MODEL_LABEL: 'Модель:',
    MATERIAL_LABEL: 'Материал:',
    COLOR_LABEL: 'Цвет:',
    SIZE_LABEL: 'Размер:',
    CURRENT_COLOR_LABEL: 'Текущий цвет:',
    COLOR_AND_SIZE_LABEL: 'Цвет и размер:',
    TABLE_SIZE_LABEL: 'Таблица размеров',
    SIZES: [40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60],
    TABLE_SIZE_HEAD: ['Росcийский размер', 'Обхват груди', 'Обхват талии', 'Обхват бедер'],
    TABLE_SIZE_BODY: [
        ['40', '76-80', '57-60', '84-88'],
        ['42', '80-84', '60-63', '88-92'],
        ['44', '84-88', '63-66', '92-96'],
        ['46', '88-92', '66-70', '96-100'],
        ['48', '92-96', '70-74', '100-104'],
        ['50', '96-100', '74-80', '104-108'],
        ['52', '100-104', '80-84', '108-112'],
        ['54', '104-108', '84-88', '112-116'],
        ['56', '108-112', '88-92', '116-120'],
        ['58', '112-116', '92-96', '120-124'],
        ['60', '116-120', '96-100', '124-128'],

    ]
};