import React from 'react';
import s from './RegistrationSetting.module.scss';
const RegistrationSetting = () => {
    return (
        <div className={s.div}>
            <h1>Управление записями</h1>
            <h1>Управление записями</h1>
            <h1>Управление записями</h1>
            <h1>Управление записями</h1>
            <h1>Управление записями</h1>
            <h1>Управление записями</h1>
        </div>
    );
};

export default RegistrationSetting;
